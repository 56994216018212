import { GreenDiv } from './landing_styles';
import { H2, PoolxLogoBlack, H3, Button, RobotIcon, H2Money, H3Box } from '../reusable-components';
import image from '../images/stadium.jpg';
import sultanStadium from '../images/sultanstadium.jpg';
import kungImage from '../images/kung_landing.png';
import kungBet from '../images/bet_kung.png';
import kungFollow from '../images/follow_kung.png';
import kungWin from '../images/win_kung.png';
import NavBar from '../navbar';
import { useTranslation } from 'react-i18next';
import useShallowRounds from '../rounds/useShallowRounds';
import { Link } from 'react-router-dom';
import 'moment/locale/sv';
import 'moment/locale/fi';
import 'moment/locale/en-gb';
import 'moment/locale/fa';
import 'moment/locale/nb';
import 'moment/locale/de';
import 'moment/locale/fr';
import 'moment/locale/ar';
import 'moment/locale/es';
import 'moment/locale/tr';
import 'moment/locale/pl';
import MediaQuery from "react-responsive/src";
import { formatStringCurrency } from "../common/CurrencyFormatter";
import { getDateString, getJackpot, getRoundName, getRoundPlate } from "../common/RoundHelper";
import { getProPickText } from '../routedround/components/couponform/components/propick';
import useDetailRound from '../rounds/useDetailRound';
import { BelowMenuContentWrapper } from "../common/BelowMenuContentWrapper";
import { TwitterTimelineEmbed } from 'react-twitter-embed';
import Progress from "../common/progress";
import { Box, Grid, List, ListItem, ListItemIcon, ListItemText, Typography } from "@mui/material";
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import SportsSoccerIcon from '@mui/icons-material/SportsSoccer';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import { getRegisterLink } from '../common/OperatorHelper';
import { usePlayer } from '../player/playerContext';
import RoundBoxSlim from './roundboxslim';
import RobotPlateSlim from './robotplateslim';
import RoundBoxWithButton from './roundboxwithbutton';
import { ShowImageOnLandingPage, UseSlimRobotPlates, gradientsC, themeC } from '../theme/themehelper';
import SyndicateBanner from './syndicatebanner';

function getRobotPlate(round, t) {
  const roundName = getRoundName(round, t)

  switch (round.name) {

    case "Maxtipset":
    case "MaxtipsetEUR":
      return (
        <div className='color-plate' style={{ background: '#B8CCF1' }}>
          <H3Box>{roundName}</H3Box>
          <div className='icon-holder'>
            <RobotIcon height="40" />
          </div>
        </div>
      );
    case "Mixtipset":
    case "MixtipsetEUR":
      return (
        <div className='color-plate' style={{ background: '#FAEEAE' }}>
          <H3Box>{roundName}</H3Box>
          <div className='icon-holder'>
            <RobotIcon height="40" />
          </div>
        </div>
      );
    case 'Flextipset6':
    case 'Flextipset8':
    case 'Flextipset10':
    case 'Flextipset6EUR':
    case 'Flextipset8EUR':
    case 'Flextipset10EUR':
      return (
        <div className='color-plate' style={{ background: '#E0D8EE' }}>
          <H3Box>{roundName}</H3Box>
          <div className='icon-holder'>
            <RobotIcon height="40" />
          </div>
        </div>
      );
    default:
      return (
        <div className='color-plate' style={{ background: '#FAEEAE' }}>
          <div className='icon-holder'>
            <RobotIcon height="60" />
          </div>
        </div>
      );
  }
}

function RoundBox({ round, t }) {
  const link = `/rounds/${round.id}`;

  const detailRoundResponse = useDetailRound(round.id);

  const turnoverAmount = round.operatorTurnover ? round.operatorTurnover.amount : 'NA';
  const turnoverCurrency = round.operatorTurnover ? round.operatorTurnover.currency : 'NA';
  const dateString = getDateString(round, t);

  let jackpot = getJackpot(turnoverCurrency, detailRoundResponse.round);

  const reportSelection = () => {

    window.gtag("event", "select_content", {
      content_type: "round",
      content_id: "round_box"
    });
  }

  return (
    <div className='round-box'>
      {getRoundPlate(round, t)}
      <div className='round-box-white'>
        <div className='round-box-details'>
          {jackpot &&
            <div className='round-box-detail'>
              <span className='detail-title'>{t('jackpot')}</span>
              <H2Money>{formatStringCurrency(jackpot, turnoverCurrency, t, true)}</H2Money>
            </div>
          }
          <div className='round-box-detail'>
            <span className='detail-title'>{t('turnover')}</span>
            <H2Money>{formatStringCurrency(turnoverAmount, turnoverCurrency, t, true)}</H2Money>
          </div>
          <div className='round-box-detail'>
            <span className='detail-title'>{t('starts')}</span>
            <H2>{dateString}</H2>
          </div>
        </div>
        <Link to={link} >
          <div onClick={() => reportSelection()}>
            <Button>{t('show_round')}</Button>
          </div>
        </Link>
      </div>
    </div>
  );
}

function getProPickSuggestions(round) {
  const isGold = window.theme === "gold";

  if (isGold) {
    switch (round.name) {
      case "Maxtipset":
      case "MaxtipsetEUR":
        return [
          { rows: 36, dif: 'Easy', numFull: 2, numHalf: 2 },
          { rows: 72, dif: 'Easy', numFull: 2, numHalf: 3 },
          { rows: 108, dif: 'Easy', numFull: 3, numHalf: 2 },
        ];
      case "Mixtipset":
      case "MixtipsetEUR":
        return [
          { rows: 36, dif: 'Easy', numFull: 2, numHalf: 2 },
          { rows: 72, dif: 'Easy', numFull: 2, numHalf: 3 },
          { rows: 108, dif: 'Easy', numFull: 3, numHalf: 2 },
        ];
      default:
        return [
          { rows: 36, dif: 'Easy', numFull: 2, numHalf: 2 },
          { rows: 72, dif: 'Easy', numFull: 2, numHalf: 3 },
          { rows: 108, dif: 'Easy', numFull: 3, numHalf: 2 },
        ];
    }
  } else {
    switch (round.name) {
      case "Maxtipset":
      case "MaxtipsetEUR":
        return [
          { rows: 72, dif: 'Easy', numFull: 2, numHalf: 3 },
          { rows: 108, dif: 'Easy', numFull: 3, numHalf: 2 },
          { rows: 192, dif: 'Easy', numFull: 1, numHalf: 6 },
        ];
      case "Mixtipset":
      case "MixtipsetEUR":
        return [
          { rows: 72, dif: 'Easy', numFull: 2, numHalf: 3 },
          { rows: 108, dif: 'Easy', numFull: 3, numHalf: 2 },
          { rows: 192, dif: 'Easy', numFull: 1, numHalf: 6 },
        ];
      default:
        return [
          { rows: 36, dif: 'Easy', numFull: 2, numHalf: 2 },
          { rows: 72, dif: 'Easy', numFull: 2, numHalf: 3 },
        ];
    }
  }

}

function ProPickBox({ sugg, round, t }) {
  const dateString = getDateString(round, t);
  const detailResponse = useDetailRound(round.id);

  const link = `/rounds/${round.id}?rows=${sugg.rows}&dif=${sugg.dif}&numFull=${sugg.numFull}&numHalf=${sugg.numHalf}`;

  let theme = window.theme;

  if (UseSlimRobotPlates(theme)) {
    return (<RobotPlateSlim round={round} t={t} link={link} detailResponse={detailResponse} dateString={dateString} sugg={sugg} />);
  }

  let operatorFeePerRow = { amount: 0 };
  if (detailResponse.round && detailResponse.round.operatorFeePerRow !== undefined && detailResponse.round.operatorFeePerRow !== null) {
    operatorFeePerRow = detailResponse.round.operatorFeePerRow;
  }

  let paddingButton = "12px 48px";

  if (theme === themeC.Totome || theme === themeC.Win90me || theme === themeC.Manotomeme
    || theme === themeC.Betfame || theme === themeC.Takbetme) {
    paddingButton = "12px 30px";
  }

  const reportSelection = () => {

    window.gtag("event", "select_content", {
      content_type: "round",
      content_id: "pro_pick_box"
    });
  }

  return (
    <div className='pro-pick-box'>
      {getRobotPlate(round, t)}
      <div className='pro-pick-box-white'>
        <div className='round-box-details'>
          <div className='round-box-detail'>
            <span className='detail-title'>{dateString}</span>
            <H2Money>{detailResponse.round && getProPickText(sugg.rows, detailResponse.round.operatorStakePerRow, operatorFeePerRow, t)}</H2Money>
          </div>
        </div>
        <div className='propick-box-detail'>
          <Link to={link}>
            <div onClick={() => reportSelection}>
              <Button padding={paddingButton}>{t('bet')}</Button>
            </div>
          </Link>
        </div>
        <span className='detail-title'>{sugg.rows} {t('rows')}</span>
      </div>
    </div>
  );
}

const getHomeUrl = () => {
  const key = 'homeUrl';
  const homeUrl = sessionStorage.getItem(key);
  if (homeUrl) {
    return homeUrl;
  }

  // If home url not found in session storage, attempt to get it using URL param, these values should be set for
  // Pariplay when initializing the auth context from the received launch URL
  let searchParams = new URLSearchParams();
  return searchParams.get(key);
}

const LandingPage = () => {

  const { t } = useTranslation();

  let [shallowRoundsResponse, de] = useShallowRounds(true, false);
  const [player, config] = usePlayer();

  const agentSyndicateBetting = config && config.agentSyndicateBetting;
  let theme = window.theme;
  let twitterTheme = theme === 'dark' ? 'dark' : 'light';

  let fullVersion = !UseSlimRobotPlates(theme);
  let showImage = ShowImageOnLandingPage(theme);

  //const twitterHandle = "PoolXBet";
  const twitterHandle = "";

  const activeRounds = shallowRoundsResponse && shallowRoundsResponse.rounds &&
    shallowRoundsResponse.rounds.filter(round => round.status === 'ACTIVE' && new Date(round.openTo) > new Date(Date.now()))
      .sort((a, b) => new Date(a.openTo) - new Date(b.openTo));

  let firstMax = [];
  let firstMix = [];
  let firstFlex = [];
  if (activeRounds) {
    firstMax = activeRounds.filter(round => round.name.indexOf("Max") > -1);
    firstMix = activeRounds.filter(round => round.name.indexOf("Mix") > -1);
    firstFlex = activeRounds.filter(round => round.name.indexOf("Flex") > -1);
  }

  let topRounds = []

  if (firstMax.length > 0) {
    topRounds.push(firstMax[0]);
  }

  if (firstMix.length > 0) {
    topRounds.push(firstMix[0]);
  }

  if (firstFlex.length > 0) {
    topRounds.push(firstFlex[0]);
  }

  let topRound = undefined;
  let link = undefined;

  if (topRounds.length > 0) {
    let sortedTopRounds = Array.from(topRounds);
    sortedTopRounds = sortedTopRounds.sort((a, b) => {
      return new Date(a.openTo) - new Date(b.openTo);
    });
    topRound = sortedTopRounds[0];
    link = `/rounds/${topRound.id}`;
  }

  let registerLink = getRegisterLink(window.operatorId);

  const homeUrl = getHomeUrl();
  const renderTopRounds = (topRounds, fullVersion) => {

    if (de.isLoading || de.isIdle) {
      return (
        <>
          <Progress />
        </>
      );
    }

    return topRounds.map((round, i) => {
      if (!round) {
        return <div />;
      }
      if (fullVersion) {
        return (
          <RoundBox key={`roundBox ${i}`} round={round} t={t} />
        );
      }
      else if (window.theme === themeC.Medina || window.theme === themeC.MedinaAr || window.theme === themeC.Blue2 || window.theme === themeC.Gold || window.theme === themeC.Kung
        || theme === themeC.Betconstruct || theme === themeC.Betconstructme || window.theme === themeC.Sultan || window.theme === themeC.Elit || window.theme === themeC.Metro
        || window.theme === themeC.Cwinz || window.theme === themeC.Betbeto) {
        return (
          <RoundBoxWithButton key={`roundBox ${i}`} round={round} t={t} />
        );
      }
      else {
        return (
          <RoundBoxSlim key={`roundBox ${i}`} round={round} t={t} />
        );
      }

    })
  }

  let backgroundImage = `url(${image})`;


  if (window.theme === themeC.Blue2) {
    backgroundImage = gradientsC.Blue2.Landing;
  }

  if (window.theme === themeC.Sultan) {
    backgroundImage = `url(${sultanStadium})`;
  }

  if (window.theme === themeC.Kung) {
    backgroundImage = `url(${kungImage})`;
  }

  if (window.theme === themeC.Betconstruct || window.theme === themeC.Betconstructme) {
    backgroundImage = gradientsC.Betconstruct.Landing;
  }

  let betIcon = `url(${kungBet})`;
  let followIcon = `url(${kungFollow})`;
  let winIcon = `url(${kungWin})`;

  topRound = undefined;
  let howTo = true;
  let text = 'white';

  return (
    <>
      <NavBar whichToUnderline="0" />

      <BelowMenuContentWrapper>
        <GreenDiv>
          <div className="logo-image">
            <MediaQuery minWidth={427}>
              <center>{PoolxLogoBlack(100)}</center>
            </MediaQuery>
            <MediaQuery maxWidth={426}>
              <center>{PoolxLogoBlack(50)}</center>
            </MediaQuery>
          </div>

          {showImage &&
            <Grid container sx={{
              width: "100%",
              height: "376px",
              background: backgroundImage,
              justifyContent: "center",
              alignItems: "center",
              backgroundPosition: "center center",
              backgroundSize: "cover",
            }}>

              <MediaQuery minWidth={950}>
                {twitterHandle.length > 0 ?
                  <div className="twitter-box-left">
                  </div>
                  : <div></div>

                }
              </MediaQuery>


              {howTo && (

                <Grid container justifyContent="center" >
                  <Grid item sm={5} height="100%">
                    <Grid container direction="column" alignItems="center"
                      className='box-in-picture'
                      sx={{
                        p: 2
                      }}
                    >

                      <List sx={{ color: text }} className='list-item'>
                        <ListItem disablePadding>
                          <ListItemIcon>
                            {theme === themeC.Kung ? <Box sx={{ backgroundImage: betIcon, width: "40px", height: "40px" }} /> :
                              <TaskAltIcon sx={{ color: text }} />}
                          </ListItemIcon>
                          <ListItemText sx={{ color: text }}
                            primary={
                              <Typography
                                color={text}
                                className='bet-follow-win'
                              >
                                {t('bet_title')}
                              </Typography>
                            }
                            secondary={
                              <Typography
                                sx={{ display: 'inline' }}
                                component="span"
                                variant="body2"
                                color={text}
                              >
                                {t('bet_text')}
                              </Typography>
                            } />
                        </ListItem>
                        {theme === themeC.Kung &&
                          <ListItem>
                            <Grid container direction="row" justifyContent="center" sx={{ marginTop: "10px" }}>
                              <Box sx={{ border: "2px solid #249CEC", width: "18px", height: "0px" }} />
                            </Grid>
                          </ListItem>
                        }
                        <ListItem disablePadding>
                          <ListItemIcon>
                            {theme === themeC.Kung ? <Box sx={{ backgroundImage: followIcon, width: "40px", height: "40px" }} /> :
                              <SportsSoccerIcon sx={{ color: text }} />}
                          </ListItemIcon>
                          <ListItemText sx={{ color: text }}
                            primary={
                              <Typography
                                color={text}
                                className='bet-follow-win'
                              >
                                {t('follow_title')}
                              </Typography>
                            }
                            secondary={
                              <Typography
                                sx={{ display: 'inline' }}
                                component="span"
                                variant="body2"
                                color={text}
                              >
                                {t('follow_text')}
                              </Typography>
                            }
                          />
                        </ListItem>
                        {theme === themeC.Kung &&
                          <ListItem>
                            <Grid container direction="row" justifyContent="center" sx={{ marginTop: "10px" }}>
                              <Box sx={{ border: "2px solid #249CEC", width: "18px", height: "0px" }} />
                            </Grid>
                          </ListItem>
                        }
                        <ListItem disablePadding>
                          <ListItemIcon>
                            {theme === themeC.Kung ? <Box sx={{ backgroundImage: winIcon, width: "40px", height: "40px" }} /> :
                              <EmojiEventsIcon sx={{ color: text }} />}
                          </ListItemIcon>
                          <ListItemText sx={{ color: text }}
                            primary={
                              <Typography
                                color={text}
                                className='bet-follow-win'
                              >
                                {t('win_title')}
                              </Typography>
                            }
                            secondary={
                              <Typography
                                sx={{ display: 'inline' }}
                                component="span"
                                variant="body2"
                                color={text}
                              >
                                {t('win_text')}
                              </Typography>
                            }
                          />
                        </ListItem>
                      </List>
                      {registerLink && player && player.id === "0" &&
                        <Grid className='list-item' sx={{ marginTop: '10px' }}>
                          <a href={registerLink} target='_top'>
                            <Button type="button" >{t('register')}</Button>
                          </a>
                        </Grid>
                      }
                    </Grid>
                  </Grid>
                </Grid>

              )
              }


              {topRound && (<div className="image-text">
                <H2 color="#fff">
                  {t('play_on')} {topRound && getRoundName(topRound, t)}
                </H2>

                <H3 color="#fff" className="starts">
                  {topRound && getDateString(topRound, t)}
                </H3>

                <Link to={link}>
                  <Button>{t('show')}</Button>
                </Link>
              </div>)
              }

              <MediaQuery minWidth={950}>
                {twitterHandle.length > 0 ?
                  <div className="twitter-box-right">

                    <TwitterTimelineEmbed
                      key={twitterHandle}
                      sourceType="profile"
                      screenName={twitterHandle}
                      theme={twitterTheme}
                      noHeader
                      noFooter
                      options={{ height: 300 }}
                    /></div>
                  : <div></div>

                }
              </MediaQuery>
            </Grid>
          }

          {showImage &&
            <div className="landing-header">
              <H2>{t('current_rounds')}</H2>
            </div>
          }

          <div className="square-grid">
            {renderTopRounds(topRounds, fullVersion)}
          </div>

          {((window.theme === themeC.Light || window.theme === themeC.Blue2) && agentSyndicateBetting) &&
            <SyndicateBanner theme={window.theme} />
          }

          {fullVersion ?
            <div className="landing-header">
              <H2>{t('pro_pick_bets')}</H2>
            </div> :
            <Typography sx={{
              fontSize: "var(--font-title-size)",
              color: "var(--font-title-color)",
              lineHeight: "30px",
              fontWeight: "var(--font-title-weight)",
              fontFamily: "var(--font-title)",
              marginTop: '29px',
              marginBottom: '10px',
              textTransform: "var(--font-title-transform)"
            }}>{t('pro_pick_bets')}</Typography>
          }

          <Grid container sx={{ maxWidth: "900px", marginTop: "10px", marginLeft: "10px", marginRight: "10px", marginBottom: "10px", flexWrap: "wrap", justifyContent: "center" }}>
            {topRounds.map((round, i) => {
              if (!round) {
                return <div></div>;
              }
              const suggestions = getProPickSuggestions(round);
              return suggestions.map((sugg, j) => {
                return (
                  <ProPickBox key={`proPickBox ${i}${j}`} round={round} t={t} sugg={sugg} />
                );
              })
            })
            }
          </Grid>

          <MediaQuery maxWidth={999}>
            {twitterHandle.length > 0 ?
              <div className="twitter-box-mobile">

                <TwitterTimelineEmbed
                  key={twitterHandle}
                  sourceType="profile"
                  screenName={twitterHandle}
                  noHeader
                  noFooter
                  options={{ height: 300 }}
                /></div>
              : <div></div>

            }

          </MediaQuery>

          {homeUrl && homeUrl !== '' ?
            <a href={homeUrl} style={{ margin: '16px 0' }}>
              <Button type="button">Go to Lobby</Button>
            </a>
            :
            <div />
          }

        </GreenDiv>


      </BelowMenuContentWrapper>


    </>
  );
};

export default LandingPage;
