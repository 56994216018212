import styled from 'styled-components';


const SignBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 11px;
  color: var(--text-black);
  height: 20px;
  width: 20px;
  position: relative;  
  z-index: 1;
  border: ${({ greenBorder }) => greenBorder ? '3px solid var(--sign-correct-border)' : '1px solid #060606'};
`;

export const SignBoxWrapper = styled(SignBox)`
  background: ${({ selected }) => selected ? 'var(--sign-background)' : 'var(--background_2)'};
`;


export const AdvancedSettleWrapper = styled.div`
  .top-div {
    background: var(--background_4);
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 170px;
  }

  .icon {
    transform: rotate(-180deg);
  }

  .sort-div {
    background: var(--off-black);
    height: 60px;
    display: flex;
    align-items: center;
    padding-left: 19px;
  }

  .span {
    color: var(--text-white);
    font-weight: bold;
  }

  .settings-div, .showing-div, filter-div {
    width: 185px;
    height: 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: var(--background_1);
    font-size: 11px;
    font-weight: bold;
  }

  .settings-div {
    padding: 0 12px;
    cursor: pointer;
  }

  .showing-div {
    padding: 0 15px;
    margin: 0 18px 0 34px;
    color: var(--text-black-dark-background);
  }

  .filter-div {
    padding: 0 12px;
    color: var(--text-white);
  }

  .filter-check {
    color: var(--text-white);
    checked: {
      color: blue
    }
  }

  .showing-click {
    cursor: pointer;
  }

  .showing-margin {
    margin: 0 12px;
    user-select: none;
  }

  /* Grid */

  .grid-div {
    display: flex;
    background: var(--background_2);
  }

  .match-div, .results-div, .systems-div {
    padding-top: 11px;
    border-right: 1px solid var(--off-black);
  }

  .rows-div {
    padding-top: 11px;
  }

  .match-div {
    width: 220px;
    min-width: 220px;
    font-size: 15px;
  }

  .text-div {
    font-size: 11px;
    font-weight: bold;
  }

  .match-margin {
    margin: 0 19px;
  }

  .result-margin {
    margin: 0 12px;
  }

  .gray {
    color: rgba(33, 31, 32, 0.5);
  }

  .match-iteration-div {
    height: 36px;
    display: flex;
    align-items: center;
    font-size: 10px;
  }

  .match-iteration-div-result {
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  .green-bg {
    background: var(--background_1) !important;
    color: var(--text-black-dark-background);
  }

  .results-div {
    width: 90px;
    min-width: 90px;
  }

  .black-div {
    background: var(--off-black);
    color: var(--text-white);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 7px 8px 4px 9px;
    font-size: 11px;
    line-height: 11px;
    margin-top: 1px;
    height: 17px;
  }

  @media screen and (max-width: 950px) {
    .black-div {
      min-width: 22px;
    }
  }

  @media screen and (min-width: 951px) {
    .black-div {
      min-width: 27px;
    }
  }

  .sign {
    font-size: 12px;
    font-weight: bold;
    margin: 0 8px;
  }

  .system-width {
    width: 100px;
    display: flex;
    justify-content: space-between;
    padding: 0 12px;
  }

  .rows-width {
    padding: 0 12px;
  }

  .rows-margin {
    margin: 0 12px 0 20px;
  }

  .result-span {
    font-size: 12px;
    font-weight: bold;
    display: flex;
    justify-content: center;
  }

  .result-rows-div {
    display: flex;
    overflow-x: scroll;
  }

  .more-info-flex {
    display: flex;
  }

  .more-info-black, .more-info-beige {
    width: 512px;
    height: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    font-size: 15px;
    cursor: pointer
  }

  .more-info-black {
    background: var(--off-black);
    color: var(--text-white);
  }

  .more-info-beige {
    background: var(--background_2);
  }

  .more-info-beige:hover {
    background: #BBBBBB;;
  }

  .more-info-table-top {
    width: calc(100% - 18px);
    background: var(--off-black);
    color: var(--text-white);
    height: 35px;
    display: flex;
    align-items: center;
    padding-left: 18px;
  }

  .more-info-table-row {
    display: flex;
    height: 30px;
    align-items: center;
    background: var(--background_2);
    padding-left: 18px;
  }

  .more-info-table-top span, .more-info-table-row span {
    flex: 1;
    font-size: 15px;
  }

  .flex3 {
    flex: 3;
  }

  .flex1 {
    flex: 1;
  }
`;