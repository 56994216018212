import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { KungBetsIcon, KungHomeIcon, KungInfoIcon, KungRoundIcon, KungSyndicateIcon } from '../images/kungicons';
import { Box, Grid, SxProps, Theme, Typography} from '@mui/material';

function MobileNavBar({ theme, realMode, agentSyndicateBetting, reportSelection }: {
    theme: string, realMode: boolean, agentSyndicateBetting: boolean,
    reportSelection: (type: string, id: string) => void
}) {
    const { t } = useTranslation();

    let mobileRoundIcon = KungRoundIcon;
    let mobileBetsIcon = KungBetsIcon;
    let mobileSyndicateIcon = KungSyndicateIcon;
    let mobileHomeIcon = KungHomeIcon;
    let mobileInfoIcon = KungInfoIcon;

    const gridStyle: SxProps<Theme> = {
        paddingTop:"10px",
        color: "var(--title-bar-color)",
        height:"10px",
        '& > a': { textDecoration: "none" }        
    }

    const textStyle: SxProps<Theme> = {
        fontSize: "12px",    
        margin:"5px"    
    }

    return (
        <Grid sx={gridStyle} container direction="row" justifyContent="space-evenly">
            <Link to="/clear">
                <Grid container direction="row" alignItems="center" flexWrap="nowrap" onClick={() => reportSelection("home", "mobile_menu")}>
                    {mobileHomeIcon}
                     {/* <Box width="1px" height="15px" border="1px solid #142346" margin="0px 0px 0px 5px" />                      */}
                </Grid>
            </Link>

            <Link to="/roundhistory">
                <Grid container direction="row" alignItems="center" flexWrap="nowrap" onClick={() => reportSelection("rounds", "mobile_menu")}>
                    {mobileRoundIcon}
                    <Typography sx={textStyle}>{t('rounds')}</Typography>
                </Grid>
            </Link>

            {realMode &&
                <Link to="/mycoupons">
                    <Grid container direction="row" alignItems="center" flexWrap="nowrap" onClick={() => reportSelection("coupons", "mobile_menu")}>
                        {mobileBetsIcon}
                        <Typography sx={textStyle}>{t('my_bets')}</Typography>
                    </Grid>
                </Link>
            }

            {agentSyndicateBetting &&
                <Link to="/playagent">
                    <Grid container direction="row" alignItems="center" flexWrap="nowrap" onClick={() => reportSelection("syndicates", "mobile_menu")}>
                        {mobileSyndicateIcon}
                        <Typography sx={textStyle}>{t('bet_with_syndicates')}</Typography>
                    </Grid>
                </Link>
            }

            <Link to="/help">
                <Grid container direction="row" alignItems="center" flexWrap="nowrap" onClick={() => reportSelection("help", "mobile_menu")}>
                    {mobileInfoIcon}
                    <Typography sx={textStyle}>{t('help')}</Typography>
                </Grid>
            </Link>
        </Grid>
    );

}

export default MobileNavBar;