// Note: Navigation bar component. Some themes uses navbarlight.js insted of this file.


import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { BlackBar } from './styles';
import { PoolxLogoWhite, PoolxLogoNavbar, TicketIconWhite, RowsIconWhite, HouseIconWhite, InfoIconWhite, LilibetLogoNavbar, CashIconWhite, HouseIconNavbar } from '../reusable-components';
import { useTranslation } from 'react-i18next';
import useAgents from '../agents/useAgents';
import MediaQuery from "react-responsive";
import { usePlayer } from "../player/playerContext";
import PlayerBalance from '../playerbalance/PlayerBalance';
import NavbarLight from './navbarlight';
import { IsMiddleEast, themeC } from '../theme/themehelper';
import usePlayerBalance from '../playerbalance/usePlayerBalance';
import { Typography, useMediaQuery } from '@mui/material';
import MobileNavBar from './mobilenavbar';


const CLASS_NAME = 'link';
const CLASS_NAME_UNDERLINE = `${CLASS_NAME} underline`;

const NavBar = ({ whichToUnderline, balanceUpdateId }) => {
  const { t } = useTranslation();
  const [player, config] = usePlayer();
  let [agents, setAgents] = useState(undefined);
  let [loading, setLoading] = useState(false);
  let [balance, loadingBalance] = usePlayerBalance(balanceUpdateId);
  useAgents(setAgents, setLoading, loading);
  const agentSyndicateBetting = config && config.agentSyndicateBetting;
  const realMode = player && player._embedded && player._embedded.mode === 'real';
  const hideBalance = config && !config.showPlayerBalance;
  const apiBetting = config && config.allowCouponFileUpload;

  let theme = window.theme;
  const isMobileScreen = useMediaQuery('(max-width: 950px)');

  const reportSelection = (type, id) => {

    window.gtag("event", "select_content", {
      content_type: type,
      content_id: id
    });

  }

  if (theme === themeC.Gold || theme === themeC.Tomato) {
    return <NavbarLight whichToUnderline={whichToUnderline} />
  }

  let homeIcon = PoolxLogoNavbar;

  switch (theme) {
    case themeC.Blue:
    case themeC.Blue2:
      homeIcon = LilibetLogoNavbar;
      break;
    case themeC.Kung:
      homeIcon = <Typography sx=
        {
          {
            fontWeight: 900,
            fontStyle: "italic",
            fontSize: "16px",
            lineHeight: "18.75px",
          }}>Supertipset</Typography>
      break;
    default:
      break;
  }

  if (IsMiddleEast(theme)) {
    homeIcon = HouseIconNavbar;
  }

  if (window.operatorId === 'LILIBET') {
    homeIcon = HouseIconNavbar;
  }

  let mobileRoundIcon = RowsIconWhite;
  let mobileBetsIcon = TicketIconWhite;
  let mobileSyndicateIcon = CashIconWhite;
  let mobileHomeIcon = HouseIconWhite;

  if (theme === themeC.Kung && isMobileScreen) {
    return <MobileNavBar theme={theme} realMode={realMode} agentSyndicateBetting={agentSyndicateBetting} reportSelection={reportSelection} />;
  }
  else {
    return (
      <BlackBar>
        <MediaQuery maxWidth={950}>
          <div id={'mobile-menu'}>
            <div id={'content'}>
              <Link className={'mobile-menu-item'} to="/clear">
                <div className={'mobile-menu-icon'} id={'icon-home'} onClick={() => reportSelection("home", "mobile_menu")}>
                  {mobileHomeIcon}
                </div>
                {theme !== themeC.Blue && theme !== themeC.Blue2 && theme !== themeC.Kung &&
                  <div id={'icon-poolx'} onClick={() => reportSelection("home", "mobile_menu")}>
                    {PoolxLogoWhite}
                  </div>
                }
              </Link>

              <Link className={'mobile-menu-item'} to="/roundhistory">
                <div className={'mobile-menu-icon'} id={'icon-results'} onClick={() => reportSelection("rounds", "mobile_menu")}>
                  {mobileRoundIcon}
                </div>
                <div>{t('rounds')}</div>
              </Link>

              {realMode && (<Link className={'mobile-menu-item'} to="/mycoupons">
                <div className={'mobile-menu-icon'} id={'icon-coupon'} onClick={() => reportSelection("coupons", "mobile_menu")}>
                  {mobileBetsIcon}
                </div>
                <div className='no-text-overflow'>{t('my_bets')}</div>
              </Link>)}

              {agentSyndicateBetting && (
                <Link id="bet-with-syndicates" className={'mobile-menu-item'} to="/playagent">
                  <div className={'mobile-menu-icon'} id={'icon-syndicate'} onClick={() => reportSelection("syndicates", "mobile_menu")}>
                    {mobileSyndicateIcon}
                  </div>
                  <div>{t('bet_with_syndicates')}</div>
                </Link>
              )}

              <Link className={'mobile-menu-item'} to="/help">
                <div className={'mobile-menu-icon'} id={'icon-help'} onClick={() => reportSelection("help", "mobile_menu")}>
                  {InfoIconWhite}
                </div>
                <div>{t('help')}</div>
              </Link>
            </div>
            {!hideBalance && (<PlayerBalance balance={balance} loading={loadingBalance} />)}
          </div>

        </MediaQuery>

        <MediaQuery minWidth={950} >
          <div className="logo" onClick={() => reportSelection("home", "desktop_menu")}>
            <Link to="/clear" id={'desktop-poolx-logo'}>
              {homeIcon}
            </Link>
          </div>
          <nav>
            <ol></ol>
            <ol></ol>
            <ol className="menu-options">
              <li onClick={() => reportSelection("rounds", "desktop_menu")}>
                <Link id="round-history" className={whichToUnderline === '4' ? CLASS_NAME_UNDERLINE : CLASS_NAME} to="/roundhistory">{t('rounds')}</Link>
              </li>
              {realMode && (
                <li onClick={() => reportSelection("coupons", "desktop_menu")}>
                  <Link id="my-coupons" className={whichToUnderline === '3' ? CLASS_NAME_UNDERLINE : CLASS_NAME}
                    to="/mycoupons">{t('my_coupons')}</Link>
                </li>
              )}
              {agentSyndicateBetting && (
                <li onClick={() => reportSelection("syndicates", "desktop_menu")}>
                  <Link id="bet-with-syndicates" className={whichToUnderline === '2' ? CLASS_NAME_UNDERLINE : CLASS_NAME} to="/playagent">{t('bet_with_syndicates')}</Link>
                </li>
              )}

              {(agents && agents.length > 0 && <li>
                <Link id="agent-admin" className={whichToUnderline === '5' ? CLASS_NAME_UNDERLINE : CLASS_NAME} to="/agentadmin">{t('agent_admin')}</Link>
              </li>)}

              {(realMode && apiBetting && <li onClick={() => reportSelection("api_betting", "desktop_menu")}>
                <Link id="api-betting" className={whichToUnderline === '7' ? CLASS_NAME_UNDERLINE : CLASS_NAME} to="/apibetting">{t('api_betting')}</Link>
              </li>)}

            </ol>
          </nav>

          <div className='navhelp'>
            {!hideBalance && <PlayerBalance balance={balance} loading={loadingBalance} />}
            <ol className="menu-options">
              <li onClick={() => reportSelection("help", "desktop_menu")}>
                <Link id="help" className={whichToUnderline === '6' ? CLASS_NAME_UNDERLINE : CLASS_NAME} to="/help">{t('help')}</Link>
              </li>
            </ol>
          </div>

        </MediaQuery>
      </BlackBar >
    );

  }
}
export default NavBar;