import React from 'react';
import {useEffect, useState} from "react";
import {usePlayer} from "../player/playerContext";
import fetchApi from "../backend/fetchApi";

const usePlayerBalance = (updateId) => {

    const [player] = usePlayer();
    const [balance, setBalance] = useState(null);
    const [loading, setLoading] = useState(true);

    let balanceUrl = '';
    if (player && player._links) {
        balanceUrl = player._links.balance.href;
    }

    async function getBalance() {

        if (!balanceUrl) {
            return;
        }

        setLoading(true);
        const result = await fetchApi(balanceUrl);
        if (result.ok) {
            const json = await result.json();
            setBalance(json.balance);
            setLoading(false);
        }
    }

    useEffect(() => {
        getBalance();
    }, [balanceUrl, updateId])

    return [balance, loading];
}

export default usePlayerBalance;
