import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { getDateString, getRoundName } from '../common/RoundHelper';
import { Box, Grid, Typography, useMediaQuery } from '@mui/material';
import { formatStringCurrency } from "../common/CurrencyFormatter";
import { ForwardArrowIcon } from "../reusable-components";
import leoPick13 from '../images/LeoPick13.png';
import leoPick12 from '../images/LeoPick12.png';
import leoPick8 from '../images/LeoPick8.png';
import { UsePoolXLogoInRoundList, gradientsC, productNamesC, themeC } from '../theme/themehelper';
import kung8 from '../images/kung8.png';
import kung12 from '../images/kung12.png';
import kung13 from '../images/kung13.png';
import { EightIcon, ThirteenIcon, TwelveIcon } from "../reusable-components";
import { ShallowRound } from '../rounds/types';

function RoundHistoryItem({ active, round }: { active: boolean, round: ShallowRound }) {

  const { t } = useTranslation();
  const { id } = round;
  let friendlyName = getRoundName(round, t);

  let link = `$/rounds/${id}`;

  const { operatorTurnover, operatorPrizePlan } = round || {};
  const { currency } = operatorTurnover || {};
  const isSmallScreen = useMediaQuery('(max-width: 580px)');

  let highestWin = "0";
  let turnoverAmount = "0";

  if (operatorTurnover) {
    turnoverAmount = operatorTurnover.amount;
  }
  if (operatorPrizePlan) {
    const { payoutGroups } = operatorPrizePlan || {};
    const [first] = payoutGroups || [];
    const { payoutPerRow } = first || {};
    if (payoutPerRow) {
      highestWin = payoutPerRow.money.amount;
    } else {
      console.error(`payout per row unavailable for first payout group of round ${round.id}`)
    }
  }

  let statusLabel = '';
  const now = new Date().getTime();
  const roundOpenTo = Date.parse(round.openTo.toString());
  if (round.status === 'ACTIVE') {
    statusLabel = t('round_status_open');

    if (now > roundOpenTo) {
      statusLabel = t('round_active_after_deadline')
    }
  }
  if (round.status === 'VOIDED') {
    statusLabel = t('round_status_voided');
  }
  if (round.status === 'PAUSED') {
    statusLabel = t('round_status_paused');
  }

  if (round.operatorTurnover === null || round.operatorTurnover === undefined) {
    return <></>;
  }

  let backGround = "#FFEE90";
  if (!active) {
    backGround = "#EDEDED";
  }

  let backgroundImage = '';

  let borderRadius = '5px';
  let logo = '';
  let padding = '11px';
  let fontSize = '12px';
  let fontWeight = '500';
  let logoWidth = '71';
  let logoHeight = '35';
  let minMaxFlexTextColor = 'var(--text-white)';
  let numberImage = "";
  let numberBackgroundHeight = "64px";
  let numberBackgroundWidth = "77px";
  let icon = <></>;

  if (window.theme === themeC.Irbet365 || window.theme === themeC.Irbet365me) {
    backgroundImage = 'linear-gradient(120deg, #1b4d42 0%, #1b2825 100%)';
    logo = leoPick13;
  }

  if (window.theme === themeC.Tomato) {
    logo = leoPick8;
    borderRadius = "0px";
    backgroundImage = 'linear-gradient(0deg,#17CB6F,#1DAB63)';
    backGround = '#FFF';
    padding = '0px';
    fontSize = '14px';
    fontWeight = '';

    if (friendlyName.indexOf("13") > 0) {
      backgroundImage = 'linear-gradient(0deg,#00A6FF,#0085FF)';
      logo = leoPick13;
    }
    if (friendlyName.indexOf("12") > 0) {
      logo = leoPick12;
      backgroundImage = 'linear-gradient(0deg,#EE44EE,#CC00FF)';
    }
  }

  let mixMaxFlex = "";
  let number = "";
  let strokeColor = "";

  if (window.theme === themeC.Medina || window.theme === themeC.MedinaAr) {
    logo = "none";
    mixMaxFlex = "Flex";
    number = "8x";
    backGround = 'var(--background_4)';
    padding = '0px';
    fontSize = '14px';
    fontWeight = '';
    backgroundImage = "linear-gradient(0deg, #212654, #212654),linear-gradient(180deg, #F37B35 0%, rgba(243, 123, 53, 0) 100%)";
    logoHeight = "50px";
    strokeColor = "linear-gradient(102.58deg, #F37834 1.33%, #F6C55E 277.24%)";

    if (round.name.indexOf("Max") > -1) {
      mixMaxFlex = "Max";
      number = "13x";
      strokeColor = "linear-gradient(98.24deg, #F3BA27 2.15%, #F6C55E 101.62%)";
      backgroundImage = "linear-gradient(0deg, #394078, #394078),linear-gradient(180deg, #F3BA27 0%, rgba(0, 0, 0, 0) 100%)";
    }
    if (round.name.indexOf("Mix") > -1) {
      mixMaxFlex = "Mix";
      number = "12x";
      strokeColor = "linear-gradient(255.21deg, #C7CCF5 13.84%, #93B9DE 112.93%)";
      backgroundImage = "linear-gradient(0deg, #282E61, #282E61),linear-gradient(180deg, #93B9DE 0%, rgba(0, 0, 0, 0) 100%)";
    }
  }

  if (window.theme === themeC.Blue2) {
    logo = "none";
    mixMaxFlex = "Mini";
    number = "8";
    backGround = 'var(--background_4)';
    padding = '0px';
    fontSize = '14px';
    fontWeight = '';
    backgroundImage = gradientsC.Blue2.Flex;
    logoHeight = "50px";

    if (friendlyName.indexOf("13") > -1) {
      mixMaxFlex = "Major";
      number = "13";
      backgroundImage = gradientsC.Blue2.Max;
    }
    if (friendlyName.indexOf("12") > -1) {
      mixMaxFlex = "Minor";
      number = "12";
      backgroundImage = gradientsC.Blue2.Mix;
    }
  }

  if (UsePoolXLogoInRoundList(window.theme)) {
    borderRadius = 'var(--radius)';
    logo = "none";
    backGround = round.status==="FINISHED"? 'var(--list-boxes-primary)':'var(--list-boxes-secondary)';
    padding = '8px';
    fontSize = '14px';
    fontWeight = '';
    backgroundImage = "";
    logoHeight = "37px";
    icon = <EightIcon height="30px" />

    if (round.name.indexOf("Max") > -1) {
      mixMaxFlex = "Max";      
      icon = <ThirteenIcon height="30px" />
    }
    if (round.name.indexOf("Mix") > -1) {
      mixMaxFlex = "Mix";      
      icon = <TwelveIcon height="30px" />
    }
    numberImage = `url(${numberImage})`;
  }

  if (window.theme === themeC.Kung) {
    logo = "none";
    mixMaxFlex = productNamesC.Kung.Flex;
    number = "8";
    backGround = 'var(--background_4)';
    padding = '0px';
    fontSize = '14px';
    fontWeight = '';
    backgroundImage = gradientsC.Kung.Flex;
    logoHeight = "50px";
    minMaxFlexTextColor = "#27FFAA";
    numberImage = kung8;
    numberBackgroundHeight = "23px";
    numberBackgroundWidth = "17.35px";

    if (round.name.indexOf("Max") > -1) {
      backgroundImage = gradientsC.Kung.Max;
      minMaxFlexTextColor = "#34C2FF";
      numberImage = kung13;
      numberBackgroundHeight = "23px";
      numberBackgroundWidth = "27.5px";
    }
    if (round.name.indexOf("Mix") > -1) {
      backgroundImage = gradientsC.Kung.Mix;
      minMaxFlexTextColor = "#EE8DFF";
      numberImage = kung12;
      numberBackgroundHeight = "23px";
      numberBackgroundWidth = "28.46px";
    }
    numberImage = `url(${numberImage})`;
  }

  if (window.theme === themeC.Sultan) {
    logo = "none";
    mixMaxFlex = "Flex";
    number = "8";
    backGround = 'var(--background_4)';
    padding = '0px';
    fontSize = '14px';
    fontWeight = '';
    backgroundImage = gradientsC.Sultan.Flex;
    logoHeight = "50px";

    if (friendlyName.indexOf("Max") > -1) {
      mixMaxFlex = "Max";
      number = "13";
      backgroundImage = gradientsC.Sultan.Max;
    }
    if (friendlyName.indexOf("Mix") > -1) {
      mixMaxFlex = "Mix";
      number = "12";
      backgroundImage = gradientsC.Sultan.Mix;
    }
  }

  if (window.theme === themeC.Betconstruct || window.theme === themeC.Betconstructme || window.theme === themeC.Elit || window.theme === themeC.Metro
    || window.theme === themeC.Cwinz || window.theme === themeC.Betbeto
  ) {
    logo = "none";
    mixMaxFlex = t('flex');
    if (window.theme === themeC.Cwinz || window.theme === themeC.Betbeto) {
      mixMaxFlex = productNamesC.Cwinz.Flex;
    }
    number = "8";
    backGround = 'var(--background_4)';
    padding = '0px';
    fontSize = '14px';
    fontWeight = '';
    backgroundImage = gradientsC.Betconstruct.Flex;
    logoHeight = "50px";


    if (round.name.indexOf("Max") > -1) {
      mixMaxFlex = t('max');
      if (window.theme === themeC.Cwinz || window.theme === themeC.Betbeto) {
        mixMaxFlex = productNamesC.Cwinz.Max;
      }
      number = "13";
      backgroundImage = gradientsC.Betconstruct.Max;
    }
    if (round.name.indexOf("Mix") > -1) {
      mixMaxFlex = t('mix');
      if (window.theme === themeC.Cwinz || window.theme === themeC.Betbeto) {
        mixMaxFlex = productNamesC.Cwinz.Mix;
      }
      number = "12";
      backgroundImage = gradientsC.Betconstruct.Mix;
    }
  }

  const smallText = {
    fontSize: fontSize,
    color: "var(--text-black-dark-background)",
    lineHeight: "18px",
    fontWeight: fontWeight,
    fontFamily: "var(--font-body)",
    width: "120px"
  };

  const smallTextCentered = {
    fontSize: fontSize,
    color: "var(--text-black-dark-background)",
    lineHeight: "18px",
    fontWeight: fontWeight,
    fontFamily: "var(--font-body)",
    textAlign: "center",
    width: "120px"
  };

  const reportSelection = () => {

    window.gtag("event", "select_content", {
      content_type: "round",
      content_id: "round_list"
    });
  }

  return (
    <Link to={link} style={{ textDecoration: 'none' }}>
      <div onClick={() => reportSelection()}>
        <Grid container wrap="nowrap" justifyContent="space-between" alignItems="center" sx={{
          backgroundColor: backGround,
          marginTop: "10px",
          paddingTop: padding,
          paddingBottom: padding,
          borderRadius: borderRadius,          
        }}>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between">
            {window.theme === 'gold' && <Typography sx={{
              fontSize: "12px",
              color: "#000000",
              lineHeight: "18px",
              fontWeight: "700",
              fontFamily: "var(--font-body)",
              width: "100px",
              marginLeft: "15px"
            }}>{friendlyName}</Typography>}

            {(window.theme === themeC.Tomato || window.theme === themeC.Medina || window.theme === themeC.MedinaAr || window.theme === themeC.Blue2 || window.theme === themeC.Sultan
              || window.theme === themeC.Betconstruct || window.theme === themeC.Betconstructme || window.theme === themeC.Irbet365 ||
              window.theme === themeC.Irbet365me || window.theme === themeC.Elit || window.theme === themeC.Metro || window.theme === themeC.Kung
              || window.theme === themeC.Cwinz || window.theme === themeC.Betbeto || UsePoolXLogoInRoundList(window.theme)) &&

              <Grid container direction='column' sx={{
                width: "100px",
                backgroundImage: backgroundImage,
                marginRight: "15px",
                justifyContent: 'center',
                height: "50px",
                borderRadius: "var(--radius)"

              }}>
                <Grid item sx={{
                  width: logoWidth,
                  height: logoHeight,
                  backgroundImage: `url(${logo})`,
                  backgroundSize: 'contain',
                  marginLeft: "15px",
                  marginRight: "15px",
                }}>

                  {(window.theme === themeC.Medina || window.theme === themeC.MedinaAr) &&
                    <Grid direction="column" container alignItems="center" sx={{ marginTop: "10px" }}>
                      <Typography sx={{
                        fontSize: "10px",
                        fontFamily: "monumentextended",
                        color: 'var(--text-black)',
                        fontWeight: "400",
                        textTransform: "uppercase",
                        marginTop: "0px"
                      }}>
                        {mixMaxFlex}
                      </Typography>
                      <Typography sx={{
                        fontSize: "24px",
                        fontFamily: "monumentextended",
                        background: strokeColor,
                        backgroundClip: "text",
                        color: "transparent",
                        display: "inline-block",
                        fontWeight: "800",
                        marginTop: "-10px"
                      }}>
                        {number}
                      </Typography>
                    </Grid>
                  }

                  {(window.theme === themeC.Blue2 || window.theme === themeC.Sultan) &&
                    <Grid direction="column" container alignItems="center" sx={{ marginTop: "5px" }}>
                      <Typography sx={{
                        fontSize: "10px",
                        fontFamily: 'var(--font-title)',
                        color: 'var(--text-white)',
                        fontWeight: "400",
                        textTransform: "uppercase",
                        marginTop: "0px"
                      }}>
                        {mixMaxFlex}
                      </Typography>
                      <Typography sx={{
                        fontSize: "24px",
                        fontFamily: 'var(--font-title)',
                        color: 'var(--text-white)',
                        fontWeight: "800",
                        marginTop: "-5px"
                      }}>
                        {number}
                      </Typography>
                    </Grid>
                  }

                  {(window.theme === themeC.Kung) &&
                    <Grid direction="column" container alignItems="center" sx={{ marginTop: "5px" }}>
                      <Typography sx={{
                        fontSize: "10px",
                        fontFamily: 'var(--font-title)',
                        color: minMaxFlexTextColor,
                        fontWeight: "700",
                        textTransform: "uppercase",
                        marginTop: "0px"
                      }}>
                        {mixMaxFlex}
                      </Typography>
                      <Box
                        sx={{
                          backgroundImage: numberImage,
                          backgroundSize: "contain",
                          backgroundRepeat: "no-repeat",
                          height: numberBackgroundHeight,
                          width: numberBackgroundWidth,
                        }}
                      ></Box>

                    </Grid>
                  }

                  {(UsePoolXLogoInRoundList(window.theme)) &&
                    <Grid direction="column" container alignItems="center" sx={{ marginTop: "5px" }}>
                      {icon}
                      <Box
                        sx={{
                          backgroundImage: numberImage,
                          backgroundSize: "contain",
                          backgroundRepeat: "no-repeat",
                          height: numberBackgroundHeight,
                          width: numberBackgroundWidth,
                        }}
                      ></Box>

                    </Grid>
                  }

                  {(window.theme === themeC.Betconstruct || window.theme === themeC.Betconstructme || window.theme === themeC.Elit
                    || window.theme === themeC.Metro || window.theme === themeC.Cwinz || window.theme === themeC.Betbeto) &&
                    <Grid direction="column" container alignItems="center" sx={{ marginTop: "5px" }}>
                      <Typography sx={{
                        fontSize: "10px",
                        fontFamily: 'var(--font-title)',
                        color: 'var(--text-white)',
                        fontWeight: "400",
                        textTransform: "uppercase",
                        marginTop: "0px"
                      }}>
                        {mixMaxFlex}
                      </Typography>
                      <Typography sx={{
                        fontSize: "24px",
                        fontFamily: 'var(--font-title)',
                        color: 'var(--text-white)',
                        fontWeight: "800",
                        marginTop: "-5px"
                      }}>
                        {number}
                      </Typography>
                    </Grid>
                  }

                </Grid>
              </Grid>}

            <Typography sx={smallText}>{getDateString(round, t)}</Typography>
            {statusLabel !== "" &&
              <Box
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems="center">
                <Typography sx={active ? smallTextCentered : smallText}>{statusLabel}</Typography>
              </Box>}

            {(round.status === 'ACTIVE' || round.status === 'FINISHED') && !isSmallScreen &&
              <Box
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems="center">
                <Typography sx={smallText}>{t('turnover')}:</Typography>
                <Typography sx={smallText}>{formatStringCurrency(turnoverAmount, currency, t, true)}</Typography>
              </Box>}

            {statusLabel === "" &&
              <Box
                display="flex"
                flexDirection="column"
                justifyContent='center'
                alignItems="center">
                <Typography sx={smallText}>{t('highest_win')}:</Typography>
                <Typography sx={smallText}>{formatStringCurrency(highestWin, currency, t, true)}</Typography>
              </Box>}
          </Box>
          <Grid sx={{paddingRight:"10px"}}>
          {ForwardArrowIcon}
          </Grid>
        </Grid>
      </div>
    </Link>
  );

}
export default RoundHistoryItem;