import i18n from "i18next";
import {initReactI18next} from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

import { TRANSLATIONS_EN } from "./en/translations";
import { TRANSLATIONS_SV } from "./sv/translations";
import { TRANSLATIONS_FA } from "./fa/translations";
import { TRANSLATIONS_NO } from "./no/translations";
import { TRANSLATIONS_FI } from "./fi/translations";
import { TRANSLATIONS_DE } from "./de/translations";
import { TRANSLATIONS_FR } from "./fr/translations";
import { TRANSLATIONS_AR } from "./ar/translations";
import { TRANSLATIONS_ES } from "./es/translations";
import { TRANSLATIONS_TR } from "./tr/translations";
import { TRANSLATIONS_PL } from "./pl/translations";

import { RULES_EN } from "./en/rules";
import { RULES_SV } from "./sv/rules";
import { RULES_FA } from "./fa/rules";
import { RULES_NO } from "./no/rules";
import { RULES_FI } from "./fi/rules";
import { RULES_DE } from "./de/rules";
import { RULES_FR } from "./fr/rules";
import { RULES_AR } from "./ar/rules";
import { RULES_ES } from "./es/rules";
import { RULES_TR } from "./tr/rules";
import { RULES_PL } from "./pl/rules";

import { API_EN } from "./en/api_betting";
import { API_SV } from "./sv/api_betting";
import { API_FA } from "./fa/api_betting";
import { API_NO } from "./no/api_betting";
import { API_FI } from "./fi/api_betting";
import { API_DE } from "./de/api_betting";
import { API_FR } from "./fr/api_betting";
import { API_AR } from "./ar/api_betting";
import { API_ES } from "./es/api_betting";
import { API_TR } from "./tr/api_betting";
import { API_PL } from "./pl/api_betting";

i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        resources: {
            en: {
                translation: TRANSLATIONS_EN,      
                rules: RULES_EN,
                api: API_EN                    
            },
            sv: {
                translation: TRANSLATIONS_SV,
                rules: RULES_SV,
                api: API_SV
            },
            fa: {
                translation: TRANSLATIONS_FA,
                rules: RULES_FA,
                api: API_FA
            },
            no: {
                translation: TRANSLATIONS_NO,
                rules: RULES_NO,
                api: API_NO
            },
            fi: {
                translation: TRANSLATIONS_FI,
                rules: RULES_FI,
                api: API_FI
            },
            de: {
                translation: TRANSLATIONS_DE,
                rules: RULES_DE,
                api: API_DE
            },
            fr: {
                translation: TRANSLATIONS_FR,
                rules: RULES_FR,
                api: API_FR
            },
            ar: {
                translation: TRANSLATIONS_AR,
                rules: RULES_AR,
                api: API_AR
            },
            es: {
                translation: TRANSLATIONS_ES,
                rules: RULES_ES,
                api: API_ES
            },
            tr: {
                translation: TRANSLATIONS_TR,
                rules: RULES_TR,
                api: API_TR
            },
            pl: {
                translation: TRANSLATIONS_PL,
                rules: RULES_PL,
                api: API_PL
            },
        },
    });

i18n.changeLanguage("en")
    .then((r) => {console.log("lang set to", r)});