
import { Grid, SxProps, Theme, Typography, useMediaQuery } from '@mui/material';
import { Link } from 'react-router-dom';
import lightSyndicate from '../images/Andelsspel_900_150.png';
import lightSyndicateMobile from '../images/Andelsspel_300_350.png';
import blueSyndicate from '../images/Syndicate_blue_900_150.png';
import blueSyndicateMobile from '../images/Syndicate_blue_300_350.png';
import { useTranslation } from 'react-i18next';
import { themeC } from '../theme/themehelper';

function SyndicateBanner({ theme }: { theme: string }) {
    const { t } = useTranslation();
    const isSmallScreen = useMediaQuery('(max-width: 950px)');

    let backgroundImage = `url(${lightSyndicate})`;
    if (isSmallScreen) {
        backgroundImage = `url(${lightSyndicateMobile})`;
    }

    if (theme === themeC.Blue2) {
        backgroundImage = `url(${blueSyndicate})`;
        if (isSmallScreen) {
            backgroundImage = `url(${blueSyndicateMobile})`;
        }
    }

    let boxInPicture: SxProps<Theme> = {
        position: "relative",
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        color: "var(--text-white)",
        padding: "20px",
        marginTop: isSmallScreen ? "-70px" : ""
    };

    let text: SxProps<Theme> = {
        fontFamily: "var(--font-title)",
        fontSize: "var(--font-title-size)",
        letterSpacing: "var(--font-title-letter-spacing)",
        lineHeight: "30px",
        fontWeight: "var(--font-title-weight)",
        margin: 0,
        textTransform: "uppercase",
        color: "var(--text-white)",
    }


    return (
        <Link to={`/playagent/`} style={{ textDecoration: 'none' }}>
            <Grid container sx={{
                width: isSmallScreen ? "300px" : "900px",
                height: isSmallScreen ? "350px" : "150px",
                background: backgroundImage,
                justifyContent: "center",
                alignItems: "center",
                backgroundPosition: "center center",
                marginTop: "30px",
                color: "var(--text-white)",
                boxShadow: "rgba(0, 0, 0, 0.2) 0px 3px 3px -2px, rgba(0, 0, 0, 0.14) 0px 3px 4px 0px, rgba(0, 0, 0, 0.12) 0px 1px 8px 0px"
            }} >
                <Grid container justifyContent="center" >
                    <Grid item sm={12} height="100%">
                        <Grid container direction="column" alignItems="center" sx={boxInPicture}>
                            <Typography sx={text}>
                                {t('bet_with_syndicates')}
                            </Typography>
                            <Typography
                                sx={{ display: 'inline', textAlign: "center" }}
                                component="span"
                                variant="body2"
                            >
                                {t('join_text')}
                            </Typography>

                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Link>
    );

}

export default SyndicateBanner

