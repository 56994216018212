import styled from 'styled-components';
import React from "react";

export const BlackBar = styled.header`
  background: var(--title-bar-background);
  display: flex; 
  font-family: var(--font-title-3);
  
  @media screen and (max-width: 950px) {
    align-items: flex-start;
    height: 75px;
    flex-direction: var(--flex-direction); 
  }

  @media screen and (min-width: 951px) {
    align-items: center;
    height: var(--navbar-height);
    padding-left: var(--coupon-left-margin);
    flex-direction: var(--flex-direction); 
  }

  nav {
    justify-content: center;
    width: 100%;
    display: flex;
    flex-direction: var(--flex-direction); 
  }

  .navhelp {
    justify-content: center;
    align-items: center;
    display: flex;
    width: 250px;
    height: var(--navbar-height);
  }

  .no-text-overflow {
    white-space: nowrap;
  }

  #mobile-menu {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding-top: 8px;
    height: 45px;
  }

  #mobile-menu #content {
    display: flex;
    flex-direction: var(--flex-direction); 
    justify-content: space-between;
    align-items: center;
    padding: 8px 24px 0 24px;
  }

  .menu-options {
    padding: 0px 0px 0px 0px;
  }

  .logo {
    margin-left: 10px;
    margin-right: 10px;    
  }

  .logo a{    
    text-decoration: none;
  }

  .mobile-menu-item {
    width: 72px;
    height: 50px;
    font-size: 15px;
    font-weight: 600;
    text-decoration: none;
    color: var(--title-bar-color);
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    margin: 0px 7px 0px 7px;
  }

  .mobile-menu-icon {
    width: 50px;
    padding: 0 0 8px 0;
    color: var(--title-bar-color);
  }

  #icon-home {
    width: 32px;
    height: 32px;
  }

  #icon-poolx svg {
    height: 16px;
  }

  #icon-coupon {
    padding: 0 0 4px 0;
  !important;
  }

  #icon-syndicate {
    padding: 0 0 4px 0;
  !important;
  }

  #icon-results {    
    height: 30px;
    width: 30px;
  }

  ol {
    display: flex;
    list-style: none;
    justify-content: space-between;
    font-weight: bold;
    font-size: 14px;
    line-height: 14px;
    flex-direction: var(--flex-direction); 
  }

  li {
    margin: 0 20px;
  }

  .link {
    color: var(--title-bar-color);
    text-decoration: none;
  }

  .underline {
    text-decoration: underline;
    color: var(--title-bar-color);
  }
`;
