import { useTranslation } from "react-i18next";
import { formatStringCurrency } from "../../../common/CurrencyFormatter";
import { Table, TableCell, TableHead, TableRow, TableBody, TableFooter } from '@mui/material';

function ResultRow({
  index,
  maxNum,
  result,
  payoutPerRow,
  rows,
  shareOfCoupon,
  isRightToLeft
}) {
  const { t } = useTranslation();
  const numCorrect = maxNum - index;
  if (!result) {
    return <div></div>
  }
  const money = payoutPerRow.money || {};
  if (!money) {
    return <div></div>
  }
  const actualPayout = Number(money.amount);
  const numOwn = result.rows;

  const totalPayoutCurrency = money.currency ? money.currency : 'SEK';

  // We cannot use the amount in result.payout as one row is not always exactly equal to one stake unit, this depends on
  // the operator currency
  const ownPayout = numOwn * actualPayout * shareOfCoupon;

  const rowSx = {
    fontSize: '11px',
    padding: '5px 14px 5px 14px',
    fontFamily: 'var(--font-body)',
    color: index % 2 !== 0 ? 'var(--text-black)' : 'var(--text-black-dark-background)'
  }

  const alternating = {
    '&:nth-of-type(odd)': {
      backgroundColor: 'var(--background_1)'
    },
    '&:nth-of-type(even)': {
      backgroundColor: 'var(--background_2)'
    },
  }

  return (

    isRightToLeft ? (
      <TableRow sx={alternating}>
        <TableCell align='right' sx={rowSx}>{formatStringCurrency(ownPayout, totalPayoutCurrency, t)}</TableCell>
        <TableCell align='right' sx={rowSx}>{numOwn} {t('st')}</TableCell>
        <TableCell sx={rowSx}>{formatStringCurrency(actualPayout, totalPayoutCurrency, t)}</TableCell>
        <TableCell align='right' sx={rowSx}>{rows ? Math.round(rows) : 'NA'} {t('st')}</TableCell>
        <TableCell align='right' sx={rowSx}>{numCorrect}</TableCell>
      </TableRow>
    ) : (
      <TableRow sx={alternating}>
        <TableCell sx={rowSx}>{numCorrect}</TableCell>
        <TableCell sx={rowSx}>{rows ? Math.round(rows) : 'NA'} {t('st')}</TableCell>
        <TableCell align='right' sx={rowSx}>{formatStringCurrency(actualPayout, totalPayoutCurrency, t)}</TableCell>
        <TableCell sx={rowSx}>{numOwn} {t('st')}</TableCell>
        <TableCell align='right' sx={rowSx}>{formatStringCurrency(ownPayout, totalPayoutCurrency, t)}</TableCell>
      </TableRow>
    )
  );
}

export default function WinInfo({ round, couponShare, isRightToLeft }) {
  const { fixtures = [] } = round || {};
  const { t } = useTranslation();

  if (!couponShare) {
    return <></>;
  }

  const { coupon } = couponShare || {};

  let win = { amount: 0, currency: 'SEK' };
  if (couponShare.operatorPreliminaryResult) {
    win = couponShare.operatorPreliminaryResult;
  }
  if (couponShare.operatorResult) {
    win = couponShare.operatorResult;
  }
  const { preliminaryResult } = coupon || {};
  const { groups: resultGroups = [] } = preliminaryResult || {};

  const { operatorPrizePlan } = round || {};
  let groups = {}

  if (operatorPrizePlan) {
    groups = operatorPrizePlan.payoutGroups
  }

  const headerSx = {
    fontSize: '11px',
    fontWeight: 'bold',
    color: 'var(--text-white)',
    padding: '5px 14px 5px 14px',
    fontFamily: 'var(--font-body)'
  }

  const footerSx = {
    fontSize: '11px',
    fontWeight: 'bold',
    padding: '5px 14px 5px 14px',
    color: 'var(--text-black)',
    backgroundColor: 'var(--background_2)',
    fontFamily: 'var(--font-body)'
  }

  return groups && groups.length > 0 ? (


    <Table>
      <TableHead sx={
        {
          background: 'var(--always-black)',
        }
      }>
        {isRightToLeft ? (
          <TableRow>
            <TableCell sx={headerSx}>{t('profit')}</TableCell>
            <TableCell align='right' sx={headerSx}>{t('your_rows')}</TableCell>
            <TableCell sx={headerSx}>{t('prize')}</TableCell>
            <TableCell align='right' sx={headerSx}>{t('winners')}</TableCell>
            <TableCell align='right' sx={headerSx}>{t('correct_rows')}</TableCell>
          </TableRow>
        ) : (
          <TableRow>
            <TableCell sx={headerSx}>{t('correct_rows')}</TableCell>
            <TableCell sx={headerSx}>{t('winners')}</TableCell>
            <TableCell align='right' sx={headerSx}>{t('prize')}</TableCell>
            <TableCell sx={headerSx}>{t('your_rows')}</TableCell>
            <TableCell align='right' sx={headerSx}>{t('profit')}</TableCell>
          </TableRow>
        )}
      </TableHead>

      <TableBody sx={
        {
          background: 'var(--off-black)',
          color: 'var(--text-white)',
          fontSize: '11px',
          fontWeight: 'bold'
        }
      }>
        {groups && groups.map((group, i) => (
          <ResultRow
            key={`${i} result row`}
            maxNum={fixtures.length}
            result={resultGroups[i]}
            index={i}
            shareOfCoupon={Number(couponShare.totalStakePercentage)}
            isRightToLeft={isRightToLeft}
            {...group}
          />
        ))}
      </TableBody>

      {isRightToLeft ? (
        <TableFooter>
          <TableRow>
            <TableCell sx={footerSx}>{formatStringCurrency(win.amount, win.currency, t)}</TableCell>
            <TableCell align='right' sx={footerSx} />
            <TableCell align='right' sx={footerSx} />
            <TableCell align='right' sx={footerSx} />
            <TableCell align='right' sx={footerSx}>{t('total')}:</TableCell>
          </TableRow>
        </TableFooter>
      ) : (
        <TableFooter>
          <TableRow>
            <TableCell sx={footerSx}>{t('total')}:</TableCell>
            <TableCell sx={footerSx} />
            <TableCell sx={footerSx} />
            <TableCell sx={footerSx} />
            <TableCell align='right' sx={footerSx}>{formatStringCurrency(win.amount, win.currency, t)}</TableCell>
          </TableRow>
        </TableFooter>
      )}
    </Table>

  ) : <></>;
}
