import { useTranslation } from 'react-i18next';
import { PoolXMoney } from '../models/money';
import { Grid, SxProps, Theme, Typography } from '@mui/material';
import { PoolXNumberFormat } from '../common/CurrencyFormatter';

const PlayerBalance = ({ balance, loading }: { balance: PoolXMoney | undefined, loading: boolean | undefined }) => {

    const { t } = useTranslation();

    const containerStyle: SxProps<Theme> = {
        backgroundColor: 'var(--navbar-background)',
        display: 'flex',
        flexDirection: 'var(--flex-direction)',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
    };

    const textStyle: SxProps<Theme> = {
        fontFamily: 'var(--font-title-3)',
        fontSize: '14px',
        fontWeight: 'bold',
        color: 'var(--title-bar-color)',
        margin: '3px'
    }

    if (balance === undefined || loading) {
        return <></>;
    }


    const nf = PoolXNumberFormat(t('locale'), { style: 'currency', currency: balance.currency, minimumFractionDigits: 2, maximumFractionDigits: 2 });

    return (
        <Grid sx={containerStyle}>
            <Typography sx={textStyle}>{t('balance')}</Typography>
            <Typography sx={textStyle}>{nf.format(balance.amount)}</Typography>
        </Grid>
    );
}

export default PlayerBalance;