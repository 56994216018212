
function toInternalLanguageCode(langCodeIso: string) {
    if (langCodeIso === 'en-US' || langCodeIso === 'EN') {
        return 'en';
    }

    if (langCodeIso === 'sv-SE' || langCodeIso === 'SV') {
        return 'sv';
    }

    if (langCodeIso === 'fa-IR' || langCodeIso === 'FA') {
        return 'fa';
    }

    return langCodeIso.toLowerCase();
}

function toInternalLanguageCodeFromOthers(lang: string) {
    if (lang === 'nb') {
        return 'no';
    }

    return lang.toLowerCase();
}

function isAllowedLang(lang: string) {
    if (lang === 'sv'
        || lang === 'no'
        || lang === 'fi'
        || lang === 'fa'
        || lang === 'en'
        || lang === 'de'
        || lang === 'fr'
        || lang === 'ar'
        || lang === 'es'
        || lang === 'tr'
        || lang === 'pl') {
        return true;
    }
    return false;
}

export function getLanguage(urlParams: URLSearchParams) {
    let languageSetTo = '';

    // Used by mock operator frame
    const lang = urlParams.get('lang');
    if (lang) {
        if (isAllowedLang(lang)) {
            return lang;
        }
    }

    // Used by others
    const language = urlParams.get("language")
    if (language) {
        const code = toInternalLanguageCodeFromOthers(language);
        if (isAllowedLang(code)) {
            return code;
        }
    }

    // Fallback to english
    return "en";
}
